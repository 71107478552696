/**
 * AMAPI Device Enums
 */

/**
 * Device command type
 */
export enum DeviceCommands {
    LOCK = 'LOCK',
    RESET_PASSWORD = 'RESET_PASSWORD',
    REBOOT = 'REBOOT',
    CLEAR_APP_DATA = 'CLEAR_APP_DATA',
    RELINQUISH_OWNERSHIP = 'RELINQUISH_OWNERSHIP',
    START_LOST_MODE = 'START_LOST_MODE',
    STOP_LOST_MODE = 'STOP_LOST_MODE',
    /** Custom commands used to send messages via Mambo companion app, not a real AMAPI command */
    BROADCAST_MESSAGE = 'BROADCAST_MESSAGE',
}

/**
 * AMAPI device password reset flags
 */
export enum ResetPasswordFlags {
    RESET_PASSWORD_FLAG_UNSPECIFIED = 'RESET_PASSWORD_FLAG_UNSPECIFIED',
    REQUIRE_ENTRY = 'REQUIRE_ENTRY',
    DO_NOT_ASK_CREDENTIALS_ON_BOOT = 'DO_NOT_ASK_CREDENTIALS_ON_BOOT',
    LOCK_NOW = 'LOCK_NOW',
}

/**
 * Device state
 */
export enum DeviceState {
    DEVICE_STATE_UNSPECIFIED = 'DEVICE_STATE_UNSPECIFIED',
    DELETED = 'DELETED',
    DISABLED = 'DISABLED',
    PROVISIONING = 'PROVISIONING',
    ACTIVE = 'ACTIVE',
    LOST = 'LOST',
    // The following are custom states, not defined by AMAPI
    PENDING_DELETE = 'PENDING_DELETE',
    PENDING_LOST_MODE = 'PENDING_LOST_MODE',
}

/**
 * Device system update status
 */
export enum UpdateStatus {
    UPDATE_STATUS_UNKNOWN = 'UPDATE_STATUS_UNKNOWN',
    UP_TO_DATE = 'UP_TO_DATE',
    UNKNOWN_UPDATE_AVAILABLE = 'UNKNOWN_UPDATE_AVAILABLE',
    SECURITY_UPDATE_AVAILABLE = 'SECURITY_UPDATE_AVAILABLE',
    OS_UPDATE_AVAILABLE = 'OS_UPDATE_AVAILABLE',
}

/**
 * Device management mode
 */
export enum ManagementMode {
    MANAGEMENT_MODE_UNSPECIFIED = 'MANAGEMENT_MODE_UNSPECIFIED',
    DEVICE_OWNER = 'DEVICE_OWNER',
    PROFILE_OWNER = 'PROFILE_OWNER',
}

/**
 * Device non-compliance status
 */
export enum NonComplianceMode {
    NON_COMPLIANCE_REASON_UNSPECIFIED = 'NON_COMPLIANCE_REASON_UNSPECIFIED',
    API_LEVEL = 'API_LEVEL',
    MANAGEMENT_MODE = 'MANAGEMENT_MODE',
    USER_ACTION = 'USER_ACTION',
    INVALID_VALUE = 'INVALID_VALUE',
    APP_NOT_INSTALLED = 'APP_NOT_INSTALLED',
    UNSUPPORTED = 'UNSUPPORTED',
    APP_INSTALLED = 'APP_INSTALLED',
    PENDING = 'PENDING',
    APP_INCOMPATIBLE = 'APP_INCOMPATIBLE',
    APP_NOT_UPDATED = 'APP_NOT_UPDATED',
    DEVICE_INCOMPATIBLE = 'DEVICE_INCOMPATIBLE',
}

/**
 * Device app install failure reason
 */
export enum InstallationFailureReason {
    INSTALLATION_FAILURE_REASON_UNSPECIFIED = 'INSTALLATION_FAILURE_REASON_UNSPECIFIED',
    INSTALLATION_FAILURE_REASON_UNKNOWN = 'INSTALLATION_FAILURE_REASON_UNKNOWN',
    IN_PROGRESS = 'IN_PROGRESS',
    NOT_FOUND = 'NOT_FOUND',
    NOT_COMPATIBLE_WITH_DEVICE = 'NOT_COMPATIBLE_WITH_DEVICE',
    NOT_APPROVED = 'NOT_APPROVED',
    PERMISSIONS_NOT_ACCEPTED = 'PERMISSIONS_NOT_ACCEPTED',
    NOT_AVAILABLE_IN_COUNTRY = 'NOT_AVAILABLE_IN_COUNTRY',
    NO_LICENSES_REMAINING = 'NO_LICENSES_REMAINING',
    NOT_ENROLLED = 'NOT_ENROLLED',
    USER_INVALID = 'USER_INVALID',
    NETWORK_ERROR_UNRELIABLE_CONNECTION = 'NETWORK_ERROR_UNRELIABLE_CONNECTION',
    INSUFFICIENT_STORAGE = 'INSUFFICIENT_STORAGE',
}

/**
 * Device current display state
 */
export enum DisplayState {
    DISPLAY_STATE_UNSPECIFIED = 'DISPLAY_STATE_UNSPECIFIED',
    OFF = 'OFF',
    ON = 'ON',
    DOZE = 'DOZE',
    SUSPENDED = 'SUSPENDED',
}

/**
 * Application event type
 */
export enum ApplicationEventType {
    APPLICATION_EVENT_TYPE_UNSPECIFIED = 'APPLICATION_EVENT_TYPE_UNSPECIFIED',
    INSTALLED = 'INSTALLED',
    CHANGED = 'CHANGED',
    DATA_CLEARED = 'DATA_CLEARED',
    REMOVED = 'REMOVED',
    REPLACED = 'REPLACED',
    RESTARTED = 'RESTARTED',
    PINNED = 'PINNED',
    UNPINNED = 'UNPINNED',
}

/**
 * Application install source
 */
export enum ApplicationSource {
    APPLICATION_SOURCE_UNSPECIFIED = 'APPLICATION_SOURCE_UNSPECIFIED',
    SYSTEM_APP_FACTORY_VERSION = 'SYSTEM_APP_FACTORY_VERSION',
    SYSTEM_APP_UPDATED_VERSION = 'SYSTEM_APP_UPDATED_VERSION',
    INSTALLED_FROM_PLAY_STORE = 'INSTALLED_FROM_PLAY_STORE',
}

/**
 * Application install state
 */
export enum ApplicationState {
    APPLICATION_STATE_UNSPECIFIED = 'APPLICATION_STATE_UNSPECIFIED',
    REMOVED = 'REMOVED',
    INSTALLED = 'INSTALLED',
}

/**
 * Severity of app state (If issues occured)
 */
export enum Severity {
    SEVERITY_UNSPECIFIED = 'SEVERITY_UNSPECIFIED',
    INFO = 'INFO',
    ERROR = 'ERROR',
}

/**
 * Device memory event type
 */
export enum MemoryEventType {
    MEMORY_EVENT_TYPE_UNSPECIFIED = 'MEMORY_EVENT_TYPE_UNSPECIFIED',
    RAM_MEASURED = 'RAM_MEASURED',
    INTERNAL_STORAGE_MEASURED = 'INTERNAL_STORAGE_MEASURED',
    EXTERNAL_STORAGE_DETECTED = 'EXTERNAL_STORAGE_DETECTED',
    EXTERNAL_STORAGE_REMOVED = 'EXTERNAL_STORAGE_REMOVED',
    EXTERNAL_STORAGE_MEASURED = 'EXTERNAL_STORAGE_MEASURED',
}

/**
 * Device power managemen event type
 */
export enum PowerManagementEventType {
    POWER_MANAGEMENT_EVENT_TYPE_UNSPECIFIED = 'POWER_MANAGEMENT_EVENT_TYPE_UNSPECIFIED',
    BATTERY_LEVEL_COLLECTED = 'BATTERY_LEVEL_COLLECTED',
    POWER_CONNECTED = 'POWER_CONNECTED',
    POWER_DISCONNECTED = 'POWER_DISCONNECTED',
    BATTERY_LOW = 'BATTERY_LOW',
    BATTERY_OKAY = 'BATTERY_OKAY',
    BOOT_COMPLETED = 'BOOT_COMPLETED',
    SHUTDOWN = 'SHUTDOWN',
}

/**
 * Device encryption state
 */
export enum EncryptionStatus {
    ENCRYPTION_STATUS_UNSPECIFIED = 'ENCRYPTION_STATUS_UNSPECIFIED',
    UNSUPPORTED = 'UNSUPPORTED',
    INACTIVE = 'INACTIVE',
    ACTIVATING = 'ACTIVATING',
    ACTIVE = 'ACTIVE',
    ACTIVE_DEFAULT_KEY = 'ACTIVE_DEFAULT_KEY',
    ACTIVE_PER_USER = 'ACTIVE_PER_USER',
}

/**
 * Device security state
 */
export enum DevicePosture {
    POSTURE_UNSPECIFIED = 'POSTURE_UNSPECIFIED',
    SECURE = 'SECURE',
    AT_RISK = 'AT_RISK',
    POTENTIALLY_COMPROMISED = 'POTENTIALLY_COMPROMISED',
}

/**
 * Device security risk reason
 */
export enum SecurityRisk {
    SECURITY_RISK_UNSPECIFIED = 'SECURITY_RISK_UNSPECIFIED',
    UNKNOWN_OS = 'UNKNOWN_OS',
    COMPROMISED_OS = 'COMPROMISED_OS',
    HARDWARE_BACKED_EVALUATION_FAILED = 'HARDWARE_BACKED_EVALUATION_FAILED',
}

/**
 * Device ownership state
 */
export enum Ownership {
    OWNERSHIP_UNSPECIFIED = 'OWNERSHIP_UNSPECIFIED',
    COMPANY_OWNED = 'COMPANY_OWNED',
    PERSONALLY_OWNED = 'PERSONALLY_OWNED',
}

/**
 * Device common criteria (strict security) status
 */
export enum CommonCriteriaModeStatus {
    COMMON_CRITERIA_MODE_STATUS_UNKNOWN = 'COMMON_CRITERIA_MODE_STATUS_UNKNOWN',
    COMMON_CRITERIA_MODE_DISABLED = 'COMMON_CRITERIA_MODE_DISABLED',
    COMMON_CRITERIA_MODE_ENABLED = 'COMMON_CRITERIA_MODE_ENABLED',
}

/**
 * The policy-specific reason the device is not in compliance with the setting.
 */
export enum SpecificNonComplianceReason {
    SPECIFIC_NON_COMPLIANCE_REASON_UNSPECIFIED = 'SPECIFIC_NON_COMPLIANCE_REASON_UNSPECIFIED',
    PASSWORD_POLICIES_USER_CREDENTIALS_CONFIRMATION_REQUIRED = 'PASSWORD_POLICIES_USER_CREDENTIALS_CONFIRMATION_REQUIRED',
    PASSWORD_POLICIES_PASSWORD_EXPIRED = 'PASSWORD_POLICIES_PASSWORD_EXPIRED',
    PASSWORD_POLICIES_PASSWORD_NOT_SUFFICIENT = 'PASSWORD_POLICIES_PASSWORD_NOT_SUFFICIENT',
    ONC_WIFI_INVALID_VALUE = 'ONC_WIFI_INVALID_VALUE',
    ONC_WIFI_API_LEVEL = 'ONC_WIFI_API_LEVEL',
    ONC_WIFI_INVALID_ENTERPRISE_CONFIG = 'ONC_WIFI_INVALID_ENTERPRISE_CONFIG',
}

export enum AmapiPubSubTypes {
    ENROLLMENT = 'ENROLLMENT',
    STATUS_REPORT = 'STATUS_REPORT',
    COMMAND = 'COMMAND',
    USAGE_LOGS = 'USAGE_LOGS',
}
