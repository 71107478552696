import { IMemberships, IResellers, ISessions, WebSocketEvent } from '@/shared/types';
import Axios from '@/plugins/axios';
import { AxiosError } from 'axios';
import jwtDecode from 'jwt-decode';
import { filter, map, mergeMap } from 'rxjs/operators';
import Vue from 'vue';
import Vuex from 'vuex';
import router from '@/router';
import { wait } from '@/util/wait';

import * as auth from './auth';
import * as reseller from './reseller';
import * as socket from './socket';
import * as team from './team';
import * as user from './user';
import { updates$ } from '../plugins/updates-subject';

Vue.use(Vuex);

export type TeamDashboardMeta = {
    deviceCount?: number;
    activeDevicesCount?: number;
    nonCompliantDeviceCount?: number;
    osCount?: Record<string, number>;
    modelCount?: Record<string, number>;
    updateStatusCount?: Record<string, number>;
};
export interface TeamDashboardMetaDTO extends TeamDashboardMeta {
    activeFields: string[];
}

export type ResellerDashboardMeta = {
    deviceCount?: number;
    teamCount?: number;
};

export interface ActiveRouteData {
    url?: string;
    baseURL?: string;
    controller: AbortController;
    params: any;
}
export interface ActiveRouteDataAndHash {
    hash: string;
    payload: ActiveRouteData;
}

export type RootState = {
    garbageData: number;
    dashboardMeta: TeamDashboardMetaDTO | ResellerDashboardMeta;
    activeRequests: Record<string, ActiveRouteData>;
    /** This is a temporary state to keep showing impersonate banner even after stopping it */
    exitingImpersonate: boolean;
    /** Store root reseller data */
    rootReseller: IResellers | null;
};

export enum dashboardMetaFields {
    'deviceCount' = 'deviceCount',
    'activeDevicesCount' = 'activeDevicesCount',
    'nonCompliantDeviceCount' = 'nonCompliantDeviceCount',
    'osCount' = 'osCount',
    'modelCount' = 'modelCount',
    'updateStatusCount' = 'updateStatusCount',
}

const store = new Vuex.Store<any>({
    state: (): RootState => ({
        garbageData: 1,
        dashboardMeta: {
            deviceCount: 0,
            nonCompliantDeviceCount: 0,
            osCount: {},
            modelCount: {},
            activeFields: [
                dashboardMetaFields.deviceCount,
                dashboardMetaFields.activeDevicesCount,
                dashboardMetaFields.nonCompliantDeviceCount,
                dashboardMetaFields.osCount,
                dashboardMetaFields.modelCount,
                dashboardMetaFields.updateStatusCount,
            ],
            teamCount: 0,
        },
        activeRequests: {},
        exitingImpersonate: false,
        rootReseller: null,
    }),
    mutations: {
        setGarbageData(state: RootState, value: number) {
            state.garbageData = value;
        },
        setDashboardMeta(state: RootState, data: TeamDashboardMetaDTO | ResellerDashboardMeta) {
            Vue.set(state, 'dashboardMeta', data);
        },
        setSpecificDashboardMeta(
            state: RootState,
            data: {
                key: keyof TeamDashboardMetaDTO | keyof ResellerDashboardMeta;
                value: any;
            },
        ) {
            Vue.set(state.dashboardMeta, data.key, data.value);
        },
        addActiveRequest(state: RootState, data: ActiveRouteDataAndHash) {
            // Get data to set
            const { hash, payload } = data;

            // Set route data
            Vue.set(state.activeRequests, hash, payload);
        },
        removeActiveRequest(state: RootState, hash: string) {
            // Delete active request
            delete state.activeRequests[hash];
        },
        cancelActiveRequest(state: RootState, hash: string) {
            // Cancel active request
            state.activeRequests[hash]?.controller.abort();
        },
        cancelAllActiveRequests(state: RootState) {
            // Cancel all active requests
            Object.values(state.activeRequests).forEach((route) => route?.controller.abort());
            Vue.set(state, 'activeRequests', {});
        },
        setExitingImpersonate(state: RootState, value: boolean) {
            Vue.set(state, 'exitingImpersonate', !!value);
        },
        setRootReseller(state: RootState, value: any) {
            Vue.set(state, 'rootReseller', value);
        },
    },
    actions: {
        // Set app init actions
        async init({ dispatch, rootState, getters }) {
            // Check system's status
            try {
                await Axios.get('/health-check', {
                    baseURL: '/api',
                });
            } catch (error: any) {
                const e = error as AxiosError;
                console.error(e);

                // Wait before retry
                await wait(1000);

                try {
                    await Axios.get('/health-check', {
                        baseURL: '/api',
                    });
                } catch (error: any) {
                    const e = error as AxiosError;
                    console.error(e);

                    // System is down - set maintenance page if not there already
                    if (window.location.pathname !== '/maintenance') {
                        await router.push('/maintenance');
                    }

                    return;
                }
            }

            console.warn('Initialised with active requests state:', rootState.activeRequests);

            // Set token watcher
            dispatch('auth/setTokenWatcher', { root: true });

            // Fetch logo
            dispatch('team/fetchLogo');
            dispatch('reseller/fetchLogo');

            // Load user
            await dispatch('loadUser');

            // Redirect out of maintenance page, by this point the server is up
            if (window.location.pathname === '/maintenance') {
                // Attempts with router.push proved to be erratic
                window.location.href = '/auth/login';
                return;
            }

            // Update theme
            await dispatch('team/handleTheme');

            // Load websocket connection
            await dispatch('socket/init');

            // Fetch dashboard meta
            await dispatch('fetchDashboardMeta');

            // Get broken subscriptions if team is a reseller
            if (getters['team/isReseller'] && getters['auth/isAuthenticated']) {
                await dispatch('reseller/fetchBrokenSubscriptions');
            }

            // Root reseller handling
            await dispatch('handleRootReseller');

            // Handle logout on current session deletion
            updates$
                .pipe(
                    // Filter for session delete events
                    filter((event: WebSocketEvent) => event.type === 'Sessions:delete'),
                    // Check if session deletion was current session
                    map((event: WebSocketEvent<ISessions>) => {
                        // Extract session
                        const { payload: session } = event;

                        // Decode current token
                        const token = jwtDecode<{ jti: string }>(rootState.auth.tokens.token);

                        // Check if session was this current session
                        return token?.jti === session.authToken;
                    }),
                    // Only handle current session deletion
                    filter((logoutUser: boolean) => logoutUser),
                    // Logout user
                    mergeMap(() => dispatch('auth/logout')),
                )
                .subscribe();
        },
        async loadUser({ dispatch, rootState }) {
            const fetch = [];

            // If authenticated set token
            if (rootState.auth.isAuthenticated) {
                Axios.defaults.headers.common.Authorization = `Bearer ${rootState.auth.tokens.token.token}`;
                await dispatch('auth/safeRefresh');
                console.log('Set token');
            }

            // Get non-auth fetch
            fetch.push(
                // Update reseller (Check domain and reseller info)
                dispatch('reseller/updateReseller'),
                // Update team (Check subdomain and team info)
                // This action also does a `user/updateUser` action which fetches latest info and memberships
                dispatch('team/setTeam'),
            );

            // Run init actions in parallel
            await Promise.all(fetch);

            // Check if user is part of current team
            await dispatch('team/checkIsMember');

            // Set reseller's available plans in store
            await dispatch('reseller/updatePlans');
        },
        async initsocket({ dispatch }) {
            // If authenticated init root state
            await dispatch('socket/init', null, { root: true });
        },
        newGarbageData({ commit, state }) {
            let random = Math.random();
            while (random === state.garbageData) random = Math.random();
            commit('setGarbageData', random);
        },
        async fetchDashboardMeta({ commit, rootState, getters, rootGetters }) {
            // Fetch only if logged in
            if (!getters['auth/isAuthenticated']) return;

            try {
                let data: ResellerDashboardMeta | TeamDashboardMetaDTO | null = null;

                if (getters['team/isReseller']) {
                    data = (await Axios.get<ResellerDashboardMeta>(`/resellers/${(rootGetters['reseller/activeReseller'] as IResellers)?.id}/dashboard`))?.data;
                } else {
                    if (!rootState.team.team.id) {
                        // No team, can't fetch dashboard meta
                        return;
                    }

                    data = (await Axios.get<TeamDashboardMetaDTO>(`/teams/${rootState.team.team.id}/dashboard`))?.data;
                }

                commit('setDashboardMeta', data);

                return data;
            } catch (error: any) {
                const e = error as AxiosError;

                console.error(e);
            }
        },
        async addActiveRequest({ commit, state }, data: ActiveRouteDataAndHash) {
            // If request exists, cancel request
            if (state.activeRequests[data.hash]) {
                console.warn('Request exists already, cancelling old duplicate request for:', data, state.activeRequests[data.hash]);
                commit('cancelActiveRequest', data.hash);
            }

            // Save active request
            commit('addActiveRequest', data);
        },
        async removeActiveRequest({ commit }, hash: string) {
            // Remove active request after deletion
            commit('removeActiveRequest', hash);
        },
        async cancelAllActiveRequests({ commit }) {
            // Cancel all active requests
            commit('cancelAllActiveRequests');
        },
        setExitingImpersonate({ commit }, value: boolean) {
            commit('setExitingImpersonate', value);
        },
        async handleRootReseller({ commit, getters }) {
            // Get all user's memberships
            const allMemberships = getters['user/parsedMemberships'] as IMemberships[];

            // Attempt to find root reseller in current memberships
            const includedRootReseller = allMemberships.find((m: IMemberships) => m.team?.resellerId === null);

            // Set root reseller if included, no need for additional fetch
            if (includedRootReseller) {
                commit('setRootReseller', includedRootReseller.team?.ofReseller);
                return;
            }

            // Find any secondary reseller teams
            const secondaryResellerTeams = allMemberships.filter((m) => !!m.team?.ofResellerId && !!m.team?.resellerId);

            // Secondary reseller teams were found
            if (secondaryResellerTeams.length) {
                // Fetch root reseller
                try {
                    const { data } = await Axios.get('/resellers', {
                        params: {
                            id: secondaryResellerTeams[0].team?.resellerId,
                        },
                    });

                    // Set root reseller
                    commit('setRootReseller', data);
                } catch (error: any) {
                    const e = error as AxiosError;
                    console.error('Unable to fetch root reseller', e);
                }
            }
        },
    },
    getters: {
        garbageData: (state) => state.garbageData,
        dashboardMeta: (state) => state.dashboardMeta,
        rootReseller: (state) => state.rootReseller,
    },
    modules: {
        auth,
        user,
        socket,
        reseller,
        team,
    },
});

export default store;
