import Vue from 'vue';
import axios from 'axios';
import { Platform } from '@/shared/types';

// Create Axios instance
const Axios = axios.create({
    baseURL: `/api/platform/${Platform.MAMBO}/v1`,
});

// Export Axios instance
export default Axios;

// Add Axios instance to Vue prototype
Vue.prototype.$axios = Axios;
