import { ITeams } from './Teams';
import { IMambo_Policies } from './Mambo_Policies';
import { IAMAPIDevice } from './amapi';
import { UpdateStatus } from '../enum';

export enum WipeDataFlags {
    WIPE_DATA_FLAG_UNSPECIFIED = 'WIPE_DATA_FLAG_UNSPECIFIED',
    PRESERVE_RESET_PROTECTION_DATA = 'PRESERVE_RESET_PROTECTION_DATA',
    WIPE_EXTERNAL_STORAGE = 'WIPE_EXTERNAL_STORAGE',
}

export interface IMambo_Devices {
    createdAt: Date;
    updatedAt: Date;
    id: string;
    teamId: string;
    team?: ITeams;
    policyId?: string;
    policy?: IMambo_Policies;
    enrollmentTime?: Date;
    amapiName?: string;
    compliant?: boolean;
    manufacturer: string;
    model: string;
    serialNumber: string;
    deviceNetworkIdentifier?: string;
    hasCompanionConnection?: boolean;
    deviceUniqueIdentifier?: string;
    imei?: string;
    meid?: string;
    osVersion?: string;
    updateStatus?: UpdateStatus;
    state?: string;
    details?: IAMAPIDevice;
    isEnrolled: boolean;
    customVariables: Record<
        string,
        string | number | boolean | null | undefined
    >;
    deviceHash: string;
    deviceFbId?: string;
    lastHeartbeatTime?: Date;
    amapiStatusReportTime?: Date;
    installedAppList?: Record<string, string>;
}
