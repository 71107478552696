export default {
    title: 'Devices',
    item: 'device | devices',
    variablesDialog: {
        title: 'Device variables',
    },
    text: 'You can add a device by using an',
    enterpriseChangedAlert: {
        text: 'We noticed you have previously deleted and rebound your AMAPI enterprise. Devices previously enrolled may not be currently enrolled under this new team and will require re-enrollment',
    },
    bulkChangeDialog: {
        title: 'Change device policy | Change devices policy',
        text: {
            p1: 'Are you sure you want to change the policy of',
            p2: 'device from | devices from',
            p3: '?',
        },
    },
    bulkDeleteDialog: {
        title: 'Delete device | Delete devices',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'device from | devices from',
            p3: '?',
        },
        notReversible:
            'This action is not reversible. It will delete the device record and issue a wipe command to the device. | This action is not reversible. It will delete them from the devices list and issue a wipe command to each device.',
    },
    bulkCommandDialog: {
        title: '{0} command',
        text: {
            p1: 'You are about to',
            p2: '1 device. | {count} devices.',
        },
    },
    changeDialog: {
        title: 'Change policy of {0} ({1})',
        text: {
            p1: 'The device currently has the policy named',
            p2: 'applied.',
        },
    },
    viewDialog: {
        title: '{0} ({1}) device',
    },

    deleteDialog: {
        title: 'Delete device',
        text: {
            p1: 'Are you sure you want to delete',
            p2: 'device with a serial number of',
            p3: 'from',
            p4: '?',
        },
        notReversible: 'This action is not reversible and it will wipe the entire device and delete it from your devices list.',
    },
    remotecontrolDialog: {
        title: 'Remote control',
    },
    commandDialog: {
        title: '',
        text: {
            p1: 'You are about to',
            p2: '.',
        },
        model: 'Model:',
        serialNumber: 'Serial number:',
        expiryInfo: 'The command expires in 10 minutes by default, but you can optionally customize it below.',
        setExpiry: 'Set custom expiry',
    },
    uploadDialog: {
        title: 'Upload variables data via CSV',
        text: {
            p1: 'You can map variables in bulk through a CSV file, we will send you an email once the import has been completed.',
            p2: 'The minimum required variables are:',
            p3: '$deviceManufacturer$, $deviceModel$, and $deviceSerialNumber$',
            p4: '. You can download a sample CSV below.',
        },
    },
    csvBulkActionsDialog: {
        title: 'Bulk actions via CSV',
        text: {
            p1: 'You can trigger device actions through CSV upload, and we will send an email with the results of each action or command undertaken.',
            p2: 'Actions applicable for this method are: policy change, trigger location, broadcast message and any device command (lock, reboot, reset password, wipe).',
            p3: 'Be aware the respective permissions for each action must be granted to your user role. Speak to your team owner(s) for role permissions if required.',
            p4: 'Learn more about how to fill the fields for each action in our',
            link: 'CSV bulk actions article',
            p5: '.',
            p6: 'A sample CSV can be downloaded below.',
        },
        beta: 'This feature is currently in beta, if you encounter any issues please feel free to report them via support or our feedback form.',
    },
    commandExpiryHelp: 'Leave empty for no expiry',
    resetPasswordOptions: {
        requireEntry: "Don't allow another reset until user enters password",
        doNotAskForCredentials: "Don't ask for password on device boot",
        lockNow: 'Lock device after password reset',
    },
    searchFields: 'model or serial number',
    deleting: 'Deleting',
    deletingInfo: "Google's services are in the process of removing the device, this might take a little while.",
    noData: 'You have not added any devices',
    handleCommandTitle: {
        password: "Reset device's password",
        default: '{0} device',
    },
    deviceStateSelect: {
        active: 'Active',
        disabled: 'Disabled',
        pendingDelete: 'Pending delete',
        provisioning: 'Provisioning',
        deleted: 'Deleted',
        unspecified: 'Unspecified',
        lost: 'Lost',
        pendingLost: 'Pending lost',
    },
    lostInfo: 'Device is currently in lost mode',
    pendingLostInfo:
        "Lost mode was triggered but it hasn't been applied to the device yet, once that happens the state will be set to lost. If, for some reason, the lost mode fails the state will revert to the actual device state.",
    pendingDeleteInfo: 'A deletion on the device has been triggered and we are awaiting for it to be applied',
    multiMatchToDelete: 'Delete 1 device | Delete {count} devices',
    matchToDelete: 'Delete {0}',
    state: 'State',
    reason: 'Reason',
    possessionWarning: 'Device has been set in lost mode with a state of {0} and the following message: {1}',
    broadcastMessageTo: 'broadcast a message to',
    resetPasswordOf: 'reset the password of',
    startLostModeOf: 'start lost mode of',
    stopLostModeOf: 'stop lost mode of',
    relinquishOwnershipOf: 'relinquish ownership of',
    defaultLostDeviceMessage: 'This device is lost. Please contact the owner on the given contact details.',
    report: {
        info: 'Assets reports get generated with the applied search and filters you currently have on the table, while app version reports includes all your devices.',
        success: {
            p1: "The request has been sent, you can go to your team's",
            reportsPage: 'reports page',
            p2: "to download the report once it's been generated.",
        },
        error: 'Unable to generate report, please try again',
    },
    columns: {
        policyCompliant: 'Policy compliant',
        androidVersion: 'Android version',
        apiLevel: 'API level',
        buildNumber: 'Build number',
        updateStatus: 'Update status',
        lastHeartbeat: 'Last heartbeat',
        lastStatusReport: 'Last status report on',
        id: 'Device Id',
    },
    policyCompliant: {
        true: 'Compliant',
        false: 'Non-compliant',
    },
    newPasswordHelpText: 'Password will be cleared if left empty. Android 14 devices with numeric passwords require to be at least 6 characters long.',
    deviceInsights: {
        title: 'Device Insights',
        notConfigureError: 'Device Insights are not configured yet. Please configure in Application policy component.',
        packagePicker: 'Select Package',
        memoryUsage: 'Memory Usage',
        batteryUsage: 'Battery Usage',
        storageUsage: 'Storage Usage',
        wifiData: 'WiFi Data',
        cellularData: 'Cellular Data',
        memoryYTitle: 'Size(kb)',
        batteryYTitle: 'Percentage',
        storageYTitle: 'Size(kb)',
        networkYTitle: 'KB',
        noDataForPackageError: 'No insights data available for the selected package yet!',
    },
};
