import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import store from '@/store';
import $meta from '@/plugins/page-metadata';
import TeamGuard from '@/router/middleware/team';
import Authenticated from '@/router/middleware/authenticated';
import Unauthenticated from '@/router/middleware/unauthenticated';
import Superadmin from '@/router/middleware/superadmin';
import onceable from '@/util/onceable';

// Layouts
const AuthLayout = () => import(/* webpackChunkName: "auth" */ '@/router/layouts/auth.vue');
const MainLayout = () => import(/* webpackChunkName: "team" */ '@/router/layouts/main.vue');
const SimpleLayout = () => import('@/router/layouts/simple.vue');
const EmptyLayout = () => import('@/router/layouts/empty.vue');

// Inject router
Vue.use(VueRouter);

// Define app routes
const routes: RouteConfig[] = [
    {
        path: '/admin',
        redirect: '/admin/dashboard',
        component: MainLayout,
        beforeEnter: (to, from, next) => Superadmin(to, from, onceable(next)),
        children: [
            {
                path: 'dashboard',
                name: 'Admin Dashboard',
                component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/dashboard.vue'),
            },
            {
                path: 'payments',
                name: 'Payments',
                component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/payments.vue'),
            },
            {
                path: 'resellers',
                name: 'Resellers',
                component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/resellers.vue'),
            },
            {
                path: 'teams',
                name: 'Teams',
                component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/teams.vue'),
            },
            {
                path: 'users',
                name: 'Users',
                component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/users.vue'),
            },
            {
                path: 'fusionAuthConfig',
                name: 'FusionAuth Config',
                component: () => import(/* webpackChunkName: "admin" */ '@/pages/admin/fusionAuthConfig.vue'),
            },
        ],
    },
    {
        path: '/auth',
        component: AuthLayout,
        children: [
            {
                path: '2fa',
                name: 'Verify 2FA',
                beforeEnter: (to, from, next) => Unauthenticated(to, from, onceable(next)),
                component: () => import(/* webpackChunkName: "auth" */ '@/pages/auth/2fa.vue'),
            },
            {
                path: 'login',
                name: 'Login',
                beforeEnter: (to, from, next) => Unauthenticated(to, from, onceable(next)),
                component: () => import(/* webpackChunkName: "auth" */ '@/pages/auth/login.vue'),
            },
            {
                path: 'samlv2',
                name: 'IDP Authentication',
                beforeEnter: (to, from, next) => Unauthenticated(to, from, onceable(next)),
                component: () => import('@/pages/auth/idpToken.vue'),
            },
            {
                path: 'register',
                name: 'Register',
                beforeEnter: (to, from, next) => Unauthenticated(to, from, onceable(next)),
                component: () => import(/* webpackChunkName: "auth" */ '@/pages/auth/register.vue'),
            },
            {
                path: 'forgot',
                name: 'Forgot Password',
                beforeEnter: (to, from, next) => Unauthenticated(to, from, onceable(next)),
                component: () => import(/* webpackChunkName: "auth" */ '@/pages/auth/forgot.vue'),
            },
            {
                path: 'recover',
                name: 'Recover Password',
                beforeEnter: (to, from, next) => Unauthenticated(to, from, onceable(next)),
                component: () => import(/* webpackChunkName: "auth" */ '@/pages/auth/recover.vue'),
            },
            {
                path: 'token',
                name: 'Verifying',
                component: () => import(/* webpackChunkName: "auth" */ '@/pages/auth/token.vue'),
            },
        ],
    },
    {
        path: '/billing',
        component: MainLayout,
        beforeEnter: (to, from, next) => Authenticated(to, from, onceable(next)),
        children: [
            {
                name: 'Billing',
                path: '',
                component: () => import(/* webpackChunkName: "billing" */ '@/pages/billing/index.vue'),
            },
        ],
    },
    {
        path: '/compliance',
        redirect: '/compliance/reports',
        component: MainLayout,
        beforeEnter: (to, from, next) => Authenticated(to, from, onceable(next)),
        children: [
            {
                name: 'Audit Log',
                path: 'audit-trails',
                component: () => import(/* webpackChunkName: "team" */ '@/pages/compliance/audit-trails.vue'),
            },
            {
                name: 'Offline Compliance',
                path: 'offline-compliance',
                component: () => import(/* webpackChunkName: "team" */ '@/pages/compliance/offline-compliance.vue'),
            },

            {
                name: 'Reports',
                path: 'reports',
                component: () => import(/* webpackChunkName: "team" */ '@/pages/compliance/reports.vue'),
            },
        ],
    },
    {
        path: '/developers',
        redirect: '/developers/webhooks',
        component: MainLayout,
        children: [
            {
                name: 'Webhooks',
                path: 'webhooks',
                beforeEnter: (to, from, next) => {
                    next = onceable(next);

                    // Resellers should not access these pages
                    if (store.getters['team/isReseller']) {
                        return next('/');
                    }

                    Authenticated(to, from, next);
                },
                component: () => import(/* webpackChunkName: "team" */ '@/pages/developers/webhooks.vue'),
            },
            {
                name: 'Access Tokens',
                path: 'access-tokens',
                component: () => import(/* webpackChunkName: "team" */ '@/pages/developers/access-tokens.vue'),
            },
        ],
    },
    {
        path: '/management',
        redirect: '/management/policies',
        component: MainLayout,
        beforeEnter: (to, from, next) => Authenticated(to, from, onceable(next)),
        children: [
            // For teams
            {
                name: 'Policy Components',
                path: 'components',
                component: () => import(/* webpackChunkName: "amapi" */ '@/pages/management/components.vue'),
            },
            {
                name: 'File Manager',
                path: 'fileManager',
                component: () => import(/* webpackChunkName: "amapi" */ '@/pages/management/fileManager.vue'),
            },
            {
                name: 'Devices',
                path: 'devices',
                component: () => import(/* webpackChunkName: "amapi" */ '@/pages/management/devices.vue'),
            },
            {
                name: 'Enrollment tokens',
                path: 'enrollments',
                component: () => import(/* webpackChunkName: "amapi" */ '@/pages/management/enrollments.vue'),
            },
            {
                name: 'Policies',
                path: 'policies',
                component: () => import(/* webpackChunkName: "amapi" */ '@/pages/management/policies.vue'),
            },
            // For resellers
            {
                name: 'Reseller Users',
                path: 'users',
                component: () => import(/* webpackChunkName: "resellerManagement" */ '@/pages/management/resellersUsers.vue'),
            },
            {
                name: 'Reseller Teams',
                path: 'teams',
                component: () => import(/* webpackChunkName: "resellerManagement" */ '@/pages/management/resellersTeams.vue'),
            },
            {
                name: 'Billing History',
                path: 'billing-history',
                component: () => import(/* webpackChunkName: "resellerManagement" */ '@/pages/management/billingHistory.vue'),
            },
        ],
    },
    {
        path: '/onboarding',
        redirect: '/onboarding/user',
        component: AuthLayout,
        beforeEnter: (to, from, next) => Authenticated(to, from, onceable(next)),
        children: [
            {
                path: 'user',
                name: 'User Onboarding',
                component: () => import(/* webpackChunkName: "user" */ '@/pages/onboarding/user.vue'),
            },
        ],
    },
    {
        path: '/team',
        redirect: '/team/settings',
        component: MainLayout,
        beforeEnter: (to, from, next) => Authenticated(to, from, onceable(next)),
        children: [
            {
                name: 'Team Data',
                path: 'data',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/team/data.vue'),
            },
            {
                name: 'Team Members',
                path: 'members',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/team/members.vue'),
            },
            {
                name: 'Team Roles',
                path: 'roles',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/team/roles.vue'),
            },
            {
                name: 'Settings',
                path: 'settings',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/team/settings.vue'),
            },
            {
                name: 'Organization',
                path: 'organization',
                component: () => import(/* webpackChunkName: "settings" */ '@/pages/team/organization.vue'),
            },
        ],
    },

    {
        path: '/users',
        component: MainLayout,
        beforeEnter: (to, from, next) => {
            next = onceable(next);

            // Go to /users/:id if possible
            if (store.getters['user/user']) {
                return next(`users/${store.getters['user/user'].details.id}`);
            } else {
                return next('/');
            }
        },
    },
    {
        path: '/users/:id',
        redirect: 'users/:id/profile',
        component: MainLayout,
        beforeEnter: (to, from, next) => Authenticated(to, from, onceable(next)),
        children: [
            {
                name: 'Profile',
                path: 'profile',
                component: () => import(/* webpackChunkName: "user" */ '@/pages/users/_id/profile.vue'),
            },
            // {
            //     name: 'Data',
            //     path: 'data',
            //     component: () =>
            //         import(
            //             /* webpackChunkName: "user" */ '@/pages/users/_id/data.vue'
            //         )
            // },
            {
                name: 'Password',
                path: 'passwords',
                component: () => import(/* webpackChunkName: "user" */ '@/pages/users/_id/passwords.vue'),
            },
            {
                name: 'Sessions',
                path: 'sessions',
                component: () => import(/* webpackChunkName: "user" */ '@/pages/users/_id/sessions.vue'),
            },
            {
                name: 'Memberships',
                path: 'teams',
                component: () => import(/* webpackChunkName: "user" */ '@/pages/users/_id/teams.vue'),
            },
        ],
    },
    {
        path: '/new',
        component: SimpleLayout,
        children: [
            {
                name: 'New',
                path: '',
                component: () => import('@/pages/new.vue'),
            },
        ],
    },
    {
        path: '/setup-2fa',
        component: SimpleLayout,
        children: [
            {
                name: 'Setup 2FA',
                path: '',
                component: () => import('@/pages/setup-2fa.vue'),
            },
        ],
    },
    {
        path: '/401',
        component: SimpleLayout,
        children: [
            {
                name: 'Unauthorized',
                path: '',
                component: () => import('@/pages/401.vue'),
            },
        ],
    },
    {
        path: '/maintenance',
        component: EmptyLayout,
        children: [
            {
                name: 'Maintenance',
                path: '',
                component: () => import('@/pages/maintenance.vue'),
            },
        ],
    },
    {
        path: '/',
        component: MainLayout,
        beforeEnter: (to, from, next) => Authenticated(to, from, onceable(next)),
        children: [
            {
                name: 'Dashboard',
                path: '',
                component: () => import(/* webpackChunkName: "team" */ '@/pages/index.vue'),
            },
        ],
    },
    {
        path: '*',
        component: MainLayout,
        children: [
            {
                name: '404',
                path: '',
                component: () => import('@/pages/404.vue'),
            },
        ],
    },
];

// Initialise router
const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes,
});

// Run global team checker
router.beforeEach((to, from, next) => TeamGuard(to, from, onceable(next)));

// Set page title on route change
router.beforeResolve((to, _, next) => {
    // Immediately proceed navigation
    next();

    // Get current reseller name
    const resellerName = store.getters['reseller/reseller']?.tenant?.name;

    // Set the page title
    $meta.setTitle((resellerName ? `${resellerName} - ` : '') + (to.name ?? 'Balloon'));
});

// Reset scroll position to top after page change
router.afterEach((to, from) => {
    // Check if the route changed to a different page
    if (to.name !== from.name) {
        // Move scroll to the top
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }
});

// Export router as default
export default router;
