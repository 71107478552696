import billing from './billing.en';
import deviceLabels from './deviceLabels.en';
import operators from './operators.en';
import supportedLanguages from './supportedLanguages.en';
import teamTable from './teamTable.en';

export default {
    billing,
    deviceLabels,
    operators,
    supportedLanguages,
    teamTable,
};
