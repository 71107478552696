import { AxiosResponse, AxiosError } from 'axios';
import router from '@/router';

import { wait } from '@/util/wait';

import Axios from './axios';

/** Run health check, if successfull redirect out of dashboard or retry original request
 *  @param {AxiosError} error - Axios error needed to retry original request
 */
async function healthCheckAttempt(error: AxiosError) {
    await Axios.get('/health-check', {
        baseURL: '/api',
    });

    // API is OK

    // Redirect to dashboard if on maintenance page
    if (window.location.pathname === '/maintenance') {
        await router.push('/').catch((e) => e);
    } else {
        if (error.config?.headers) {
            // Set no-retry custom header to prevent more than 1 retry
            error.config.headers['x-no-retry'] = 'true';
        }

        // Retry original request
        try {
            error.config && Axios.request(error.config);
        } catch (e) {
            console.error('Retry of original request failed but health-check is OK', e);
        }
    }
}

Axios.interceptors.response.use(
    (response: AxiosResponse) => response,
    async (error: AxiosError) => {
        // Check for server error
        console.debug('Axios error response:', error);

        const isFirstTry = error?.config?.headers?.['x-no-retry'] !== 'true' ?? true;

        const status = error?.response?.status;

        if (
            // Only retry if it's an internal server error or timeout
            (status === 500 || status === 504) &&
            // Avoid infinite loop within this interceptor
            error.config?.url !== '/health-check' &&
            isFirstTry
        ) {
            // Run health check
            try {
                await healthCheckAttempt(error);
            } catch (healthCheckError: any) {
                const e = healthCheckError as AxiosError;
                console.error(e);

                // Wait before retry
                await wait(1000);

                // Retry health-check
                try {
                    await healthCheckAttempt(error);
                } catch (healthCheckError: any) {
                    const e = healthCheckError as AxiosError;
                    console.error(e);

                    // System is down - set maintenance page if not there already
                    if (window.location.pathname !== '/maintenance') {
                        await router.push('/maintenance').catch((e: any) => e);
                    }
                }
            }
        }

        return Promise.reject(error);
    },
);
